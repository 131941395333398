function output_limited() {
  // 確認画面WhyとHow取得
  const outputWhy = document.querySelectorAll('.output_why');
  const outputHow = document.querySelectorAll('.output_how');

  outputWhy.forEach(why => {
    // 各行を配列の要素に分ける
    let lines = why.textContent.split("\n");
    let whyLen = why.textContent.length

    // 行数または文字数を超えた場合
    if (lines.length >= 6 || whyLen >= 90) {
      why.style.fontSize = 11 + 'px';
    } else if (lines.length >= 5 || whyLen >= 70) {
      why.style.fontSize = 12 + 'px';
    }
  });

  outputHow.forEach(how => {
    // 各行を配列の要素に分ける
    let lines = how.textContent.split("\n");
    let howLen = how.textContent.length

    // 行数または文字数を超えた場合
    if (lines.length >= 6 || howLen >= 120) {
      how.style.fontSize = 11 + 'px';
    } else if (lines.length >= 5 || howLen >= 100) {
      how.style.fontSize = 12 + 'px';
    }
  });
}
window.addEventListener("load", output_limited);