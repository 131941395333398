function card_choose() {
  const bestImgBtns = document.querySelectorAll('.best-img-btn');

  const parent = document.getElementById('god-select');

  const over_alert = document.getElementById('over_gods');

  bestImgBtns.forEach(bib => {
    bib.addEventListener('click', () => {

      // bibのvalue: best.idをパースして取得
      const bestId = JSON.parse(bib.value);
      
      // hidden_fieldのvalueにフォームで送信するためのbestIdをセット
      const currentBest = document.getElementById('current-best');
      currentBest.value = bestId;

      // godカードを表示するためのinputタグ生成
      let child = document.createElement('input');
      // bibのsrcを取得
      let cardSrc = bib.getAttribute('src');

      // godカード７枚制限
      let god_count = n + 1;
      if (god_count <= 7) {
        // クリックしたbestカードを非表示
        bib.style.display = "none";
        // bestsから選んだカードからgodカードを生成(呼び出し)
        addGod(child, cardSrc, bestId, bib);
      } else {
        over_alert.style.display = "block";
      }

      textAdd();
      enterBtnAdd();
    });
  });

  let n = 0;

  // bestsから選んだカードからgodカードを生成
  function addGod(child, cardSrc, bestId, bib) {
    n++;
    child.type = "image";
    child.src = cardSrc;
    child.value = bestId;
    child.id = "myCardId" + bestId;
    child.classList = "card_size_1 card_size_3 my_cards";
    child.draggable = "true";
    child = parent.appendChild(child);

    child.addEventListener('click', () => {

      n--;

      // child(godの画像サブミット)のvalue: god.idをパースして取得
      const godId = JSON.parse(child.value);

      // hidden_fieldのvalueにフォームで送信するためのgodIdをセット
      const currentGod = document.getElementById('current-god');
      currentGod.value = godId;

      // クリックしたgodカードを非表示
      child.style.display = "none";
      // bestカードを表示に戻す
      bib.style.display = "block";

      // godカードクリックでflashメッセージ非表示
      over_alert.style.display = "none";
      // godカードクリックで決定ボタン非表示
      cardEnterBtn.style.display = "none";
      // godカードクリックで次へボタンを非アクティブ
      nextBtn.classList.remove('active_btn');

      textRemove();
    });
  };


  // godカード７枚の時、決定ボタン表示
  const cardEnterBtn = document.getElementById('card_enter_btn');
  // godカード７枚の時、次へボタンをアクティブ
  const nextBtn = document.getElementById('choose_next_btn');
  
  function enterBtnAdd() {
    if (n == 7) {
      cardEnterBtn.style.display = "block";
      nextBtn.classList.add('active_btn');
    }
  }

  
  // テキスト関係の表示切り替え
  const bottomText = document.getElementById('bottom-select-p');

  function textAdd() {
    if (n >= 1) {
      bottomText.style.display = "block";
    }
  };

  function textRemove() {
    if (n == 0) {
      bottomText.style.display = "none";
    }
  };


  // 選択カード並び替えを以降のページに反映させる為の配列情報をlink_toのパラーメーターに付与
  const submitBtn = document.getElementById('submit');
  const MSBtn = document.getElementById('move_submit');
  
  if (submitBtn) {
    const valueArr = [];
  
    submitBtn.addEventListener('click', () => {
      const sortChild = parent.children;
  
      for (let i = 0; i < sortChild.length; ++i) {
        if (sortChild[i].style.display != "none") {
          valueArr.push(sortChild[i].value);
        }
      };
  
      // submitBtn.href = submitBtn.href + valueArr;
      submitBtn.href += valueArr;
    });

    
    MSBtn.addEventListener('click', () => {
      const sortChild = parent.children;
  
      for (let i = 0; i < sortChild.length; ++i) {
        if (sortChild[i].style.display != "none") {
          valueArr.push(sortChild[i].value);
        }
      };
  
      // MSBtn.href = MSBtn.href + valueArr;
      MSBtn.href += valueArr;
    });
  }
  
}
window.addEventListener("load", card_choose);