function input_limited() {
  // 入力できる行数の最大値
  const maxLineNum = 6;

  // テキストエリアの取得
  const editInput = document.querySelectorAll('.edit_input');
  // 文字数表示要素取得
  const countBox = document.querySelectorAll('.word_count');

  // 入力ごとに呼び出されるイベントを設定
  editInput.forEach((textarea, index) => {

    // 選択中textareaの文字数要素のみを表示するためにeditInput配列のindexをcountBox配列に当てはめる
    let box = countBox[index];

    textarea.addEventListener('focus', () => {
      box.style.display = "block";
      counter(textarea, index, box);
    });

    textarea.addEventListener('blur', () => {
      box.style.display = "none";
    });


    textarea.addEventListener('input', () => {
      counter(textarea, index, box);
  
      // 各行を配列の要素に分ける
      let lines = textarea.value.split("\n");
  
      // 入力行数が制限を超えた場合
      if (lines.length > maxLineNum) {
  
        var result = "";
  
        for (var i = 0; i < maxLineNum; i++) {
          result += lines[i] + "\n";
        }
  
        textarea.value = result;
      }
    }, false);
  });


  function counter(textarea, index, box) {
    let textLen = textarea.value.length;
    
    if (index % 2 == 0) {
      if (textLen <= 100) {
        box.innerHTML = `残り${100 - textLen}文字`;
      } else {
        box.innerHTML = `${textLen - 100}文字オーバー`;
      }
    } else {
      if (textLen <= 130) {
        box.innerHTML = `残り${130 - textLen}文字`;
      } else {
        box.innerHTML = `${textLen - 130}文字オーバー`;
      }
    }
  };
}
window.addEventListener("load", input_limited);